<template>
  <div>
    Carpark View Page - Not Complete
  </div>
</template>

<script>
export default {
  name: "carpark-view"
}
</script>

<style scoped>

</style>